export const ZAPIER_UNLIMITED = 'unlimited';
export type ZAPIER_UNLIMITED = typeof ZAPIER_UNLIMITED;

export type PermissionPlan = {
  admob: boolean;
  vimeo: boolean;
  numberOfRecords: number;
  numberOfMembers: number;
  customDomain: boolean;
  pushNotifications: boolean;
  iOS: boolean;
  android: boolean;
  PWA: boolean;
  capacity: number;
  zapier: number | ZAPIER_UNLIMITED;
  numberOfApps: number;
  googleAnalytics: boolean;
  googleAuth2FA: boolean;
};

export type ListPlanItem = {
  id: number;
  name: string;
  planId: string;
  permission: PermissionPlan;
  amountMonth: number;
  amountYear: number;
  currency: string;
  stripeProductId: string;
  isDefault: boolean;
  active: boolean;
  isRecommended?: boolean;
  description?: string;
};

export enum PlanPermissionKey {
  capacity = 'capacity',
  numberOfApps = 'numberOfApps',
  numberOfMembers = 'numberOfMembers',
  numberOfRecords = 'numberOfRecords',
  zapier = 'zapier',
}
export type PlanPermissionItemDetail = {
  current: number;
  max: number;
};
export type PlanPermissionResponse = {
  capacity: PlanPermissionItemDetail;
  numberOfApps: PlanPermissionItemDetail;
  numberOfMembers: PlanPermissionItemDetail;
  numberOfRecords: PlanPermissionItemDetail;
  zapier: PlanPermissionItemDetail;
};

export enum PlanModalType {
  Default = 'Default',
  ReachedAppLimitation = 'ReachedAppLimitation',
  ReachedRecordLimitation = 'ReachedRecordLimitation',
  ReachedCapacityLimitation = 'ReachedCapacityLimitation',
  ReachedAPILimitation = 'ReachedAPILimitation',
  ReachedMembersLimitation = 'ReachedMembersLimitation',
}
