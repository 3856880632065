import { API_ENDPOINTS } from '@common/constants/apiEndpoint';
import { Method } from '@common/constants/shared';
import { ComponentType, IObj, IRecord } from '@common/types';
import * as _ from 'lodash';
import axiosInstance from './axiosService';
import { ClientProps, AppInfo } from 'click-types';
import {
  FindScreenBindingRequestParams,
  FindScreenBindingResult,
  GetComponentBindingRequestParams,
  GetComponentBindingResult,
} from 'click-types';
import { AxiosResponse } from 'axios';
import store from '@common/redux/store';
import { planPermissionSelector } from '@common/redux/selectors/planPermission';
import { checkActionHasLocation } from '@common/utils/handleActions/func/helps';
import getCurrentLocation from '@common/utils/handleActions/func/getCurrentLocation';

/**
 * Make a `ClientProps` object from a object containing all the environment variables.
 *
 * @param dependencies
 * @returns a ClientProps object
 */
export function makeClientPropsFromDependencies(
  dependencies: Record<string, any> = {}
): ClientProps {
  const appInfo: AppInfo = {
    name: dependencies.appInfor.APP_NAME,
    version: dependencies.appInfor.APP_VERSION,
  };
  const clientProps: ClientProps = {
    appInfo: appInfo,
    timeZone: dependencies.timeZone,
    locale: dependencies.locale,
    currentLocation: {
      latitude: dependencies.currentLocation?.lat,
      longitude: dependencies.currentLocation?.lng,
    },
    routeParams: dependencies.routeParam,
    listIds: dependencies.currentListIds,
    valueInputs: dependencies.valueInputs,
    createRecordId: dependencies.createRecordId,
    sortedItemNumber: dependencies.sortedItemNumber,
    univaPaySubscriptionId: dependencies.idUnivaPaySubscription,
    transactionId: dependencies.transactionId,
    customActions: dependencies.customActions,
  };
  return clientProps;
}

export const stringObject = (value: any = {}) => JSON.stringify(value);

/**
 * @deprecated
 */
export const fetchBindingValue = async (
  data: any,
  dependencies: any,
  controller?: any
) => {
  const response = await axiosInstance({
    method: 'post',
    url: 'v3/request-api',
    params: {
      appId: dependencies.appInfor.id,
    },
    data: {
      method: Method.CREATE,
      service: API_ENDPOINTS.CONVERT_VALUE,
      query: {
        clientValues: makeClientPropsFromDependencies(dependencies),
      },
      data: JSON.stringify(data),
    },
    ...(controller && { signal: controller.signal }),
  });

  return response.data;
};

/**
 * Returns the screen binding data, optionally you can specify
 * a list of object ids and in which case, only the binding data for
 * these objects will be returned.
 */
export const getScreenBinding = async ({
  appId,
  dependencies,
  screenUuid,
  controller,
  objectIds,
}: {
  appId: string;
  dependencies: Record<string, any>;
  screenUuid: string;
  controller?: any;
  objectIds?: string[];
}): Promise<FindScreenBindingResult> => {
  const isActionHasLocation = checkActionHasLocation(dependencies);
  if (isActionHasLocation) {
    const currentLocation = await getCurrentLocation();
    if (currentLocation?.coords) {
      dependencies = {
        ...dependencies,
        currentLocation: {
          lat: currentLocation.coords.latitude,
          lng: currentLocation.coords.longitude,
        },
      };
    }
  }
  const findScreenBindingRequestParams: FindScreenBindingRequestParams = {
    appId: appId,
    clientValues: makeClientPropsFromDependencies(dependencies),
    screenUuid: screenUuid,
    objectIds: objectIds,
  };
  const response: AxiosResponse<FindScreenBindingResult> = await axiosInstance({
    method: 'get',
    url: API_ENDPOINTS.BINDING_SCREEN,
    params: findScreenBindingRequestParams,
    ...(controller && { signal: controller.signal }),
  });
  return response.data;
};

export const getRecordById = async (
  appId: string,
  databaseUuid: string,
  recordId: string
) => {
  const response = await axiosInstance({
    method: 'get',
    url: `v3/database/${databaseUuid}/records/${recordId}`,
    params: {
      appId,
    },
  });
  return response.data;
};

/**
 * Fetches all the binding data for a given component, identified by its id.
 *
 * @param
 * @returns the binding data for a given component.
 */
export const fetchComponentBinding = async ({
  appId,
  dependencies,
  screenUuid,
  controller,
  idComponent,
  page = 1,
  limit,
  filterText,
}: {
  appId: string;
  dependencies: Record<string, any>;
  screenUuid: string;
  idComponent: string;
  controller?: any;
  page?: number;
  limit?: number;
  filterText?: string;
}): Promise<GetComponentBindingResult> => {
  const getComponentBindingRequestParams: GetComponentBindingRequestParams = {
    appId: appId,
    clientValues: makeClientPropsFromDependencies(dependencies),
    screenUuid: screenUuid,
    page: page,
    limit: limit,
    search: filterText,
  };

  const response: AxiosResponse<GetComponentBindingResult> =
    await axiosInstance({
      method: 'get',
      url: `${API_ENDPOINTS.BINDING_SCREEN}/${idComponent}`,
      params: getComponentBindingRequestParams,
      ...(controller && { signal: controller.signal }),
    });

  return response.data;
};

export const fetchRecordList = async ({
  obj,
  controller,
  page,
  dataBinding,
  dependencies,
}: {
  obj: IObj;
  controller?: any;
  page: number;
  dataBinding: IObj;
  dependencies: IObj;
}) => {
  const tableId = _.get(dataBinding, 'source.tableId');
  const { appInfor } = dependencies;

  // const { pageSize = 100, limit } = dataBinding?.source?.options || {};
  const options = dataBinding?.source?.options || {};
  const { limit } = options;
  let { pageSize } = options;
  if (!pageSize) {
    pageSize = limit || -1;
  }

  const objectType = _.get(obj, 'type');

  const isWeb3List = objectType === ComponentType.web3List;

  const componentBindingResult: GetComponentBindingResult =
    await fetchComponentBinding({
      appId: appInfor.id,
      dependencies,
      screenUuid: obj?.screenUuid,
      controller,
      idComponent: obj.id,
      limit: pageSize * page,
    });

  if (Array.isArray(componentBindingResult?.data)) {
    const reverse = _.get(obj, 'dataBinding.source.options.reverse', false);

    const formatRecord = componentBindingResult.data.map((record: IRecord) => ({
      ...record,
      _meta: {
        componentId: obj.id,
        tableId: tableId,
        datasourceId: obj.initId || obj.id,
        databindingId: dataBinding?.id,
        ...(isWeb3List && {
          // TODO: Remove this field as this is not used.
          web3Config: _.pick(componentBindingResult, [
            'address',
            'token_address',
            'network',
            'apiToken',
          ]),
        }),
      },
    }));
    const recordAPi = reverse ? formatRecord.reverse() : formatRecord;

    const limitPage = _.ceil(limit / pageSize);
    const responseTotal = Number(componentBindingResult.total);

    const dataTotalPage = _.ceil(responseTotal / pageSize);

    const totalPage =
      limit && limitPage <= dataTotalPage ? limitPage : dataTotalPage;

    const total = !limit
      ? responseTotal
      : responseTotal < limit
      ? responseTotal
      : limit;

    return { total, data: recordAPi, totalPage, limit };
  }

  return {
    data: componentBindingResult.data,
    total: componentBindingResult.total,
  };
};

export const fetchScreenAction = async ({
  clientValues,
  screenUuid,
  actionId,
  objectId,
  screenAction = false,
  data,
}: {
  clientValues: IObj;
  screenUuid: string;
  data?: IObj;
  actionId: string;
  objectId?: string;
  screenAction?: boolean;
}) => {
  const state = store.getState();
  const planPermission = planPermissionSelector(state);
  const isReachedNumberOfRecord =
    planPermission.numberOfRecords.current >=
    planPermission.numberOfRecords.max;
  if (isReachedNumberOfRecord) {
    alert('プランの上限を超えています。管理者に問い合わせてください。');
    return;
  }
  const isActionHasLocation = checkActionHasLocation(clientValues);
  if (isActionHasLocation) {
    const currentLocation = await getCurrentLocation();
    if (currentLocation?.coords) {
      clientValues = {
        ...clientValues,
        currentLocation: {
          lat: currentLocation.coords.latitude,
          lng: currentLocation.coords.longitude,
        },
      };
    }
  }

  const response = await axiosInstance({
    method: 'post',
    url: API_ENDPOINTS.ACTION_SCREEN,
    params: {
      appId: clientValues.appInfor?.id,
      screenUuid,
      actionId,
      ...(!screenAction && { objectId }),
      ...(screenAction && { screenAction }),
      clientValues: makeClientPropsFromDependencies(clientValues),
    },
    ...(data && { data }),
  });

  return response.data;
};

// get list object
export const getListOptions = async ({
  appId,
  fieldId,
  screenUuid,
  objectId,
  dependencies,
  limit,
}: {
  appId: string;
  fieldId: string;
  screenUuid: string;
  objectId: string | undefined;
  dependencies: object;
  limit: number;
}) => {
  const response = await axiosInstance({
    method: 'get',
    url: `${API_ENDPOINTS.DATABASE_LIST_OPTION}/${fieldId}`,
    params: {
      limit,
      screenUuid,
      objectId,
      appId,
      clientValues: makeClientPropsFromDependencies(dependencies),
    },
  });

  return response.data;
};

// payment compnents
export type StripePaymentParams = {
  appId: string;
  screenUuid: string;
  objectId: string;
  dependencies: any;
  destination: string;
  paymentMethodId: string;
  subscription: boolean;
};
export const stripePayment = async (params: StripePaymentParams) => {
  const {
    screenUuid,
    appId,
    objectId,
    dependencies,
    destination,
    paymentMethodId,
    subscription,
  } = params;

  const { data }: any = await axiosInstance({
    method: 'post',
    url: 'v3/payment-intent',
    data: {
      destination,
      paymentMethodId,
      subscription,
    },
    params: {
      screenUuid,
      appId,
      objectId,
      clientValues: makeClientPropsFromDependencies(dependencies),
    },
  });

  return data;
};

export type UnivaPayParams = {
  paymentType: string;
  period: string;
  type: string;
  dataCard: {
    cardholder: string;
    card_number: string;
    exp_month: string;
    exp_year: string;
    cvv: string;
  };
  appId: string;
  screenId: string;
  objectId: string;
  saveCardOptions: { saveCard: boolean; onlySaveCard: boolean };
  dependencies: any;
};

export const univaPayPayment = async (params: UnivaPayParams) => {
  const {
    screenId,
    appId,
    objectId,
    dataCard,
    paymentType,
    period,
    type,
    saveCardOptions,
    dependencies,
  } = params;

  const { data }: any = await axiosInstance({
    method: 'post',
    url: 'v3/payment-univapay-component',
    data: {
      dataCard,
      paymentType,
      period,
      type,
      saveCardOptions,
    },
    params: {
      screenId,
      appId,
      objectId,
      clientValues: makeClientPropsFromDependencies(dependencies),
    },
  });

  return data;
};

export const resetPassword = async (
  appId: string,
  data: Record<string, any>
) => {
  const resp = await axiosInstance({
    method: 'post',
    url: API_ENDPOINTS.v3_RESET_PASSWORD,
    data,
    params: {
      appId,
    },
  });

  return resp;
};

export const forgotPassword = async (
  appId: string,
  data: Record<string, any>
) => {
  const resp = await axiosInstance({
    method: 'post',
    url: API_ENDPOINTS.V3_FORGOT_PASSWORD,
    data,
    params: {
      appId,
    },
  });

  return resp;
};
