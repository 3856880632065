import { HEIGHT_STATUS_BAR } from '@common/constants/shared';
import { appInfoSelector } from '@common/redux/selectors/app';
import { dimensionSelector, pagesSelector } from '@common/redux/selectors/page';
import { useLocation } from '@common/routes/hooks';
import BindingComponent from '@common/screens/RenderScreen/BindingComponent';
import {
  getComponentChild,
  mapPropColor,
} from '@common/screens/RenderScreen/utils';
import { ElementType, ObjectRenderProps } from '@common/types/element';
import { find, get, isNil, sortBy } from 'lodash';
import qs, { ParsedQuery } from 'query-string';
import React, { FC, Fragment, useMemo } from 'react';
import { Platform, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import { mapping, notScaleComponent } from './func';

const ObjectRender = ({
  arrComp,
  isScreen,
  layout,
  isShowStatusBar,
  isFromViewLive,
  currentArrComp,
  screen,
}: ObjectRenderProps) => {
  const dimension = useSelector(dimensionSelector);
  const appInfo = useSelector(appInfoSelector);

  const insets = useSafeAreaInsets();

  const { search } = useLocation();

  const query: ParsedQuery<any> = qs.parse(search);

  const pages = useSelector(pagesSelector);

  const currentPageInfo = useMemo(
    () => (screen ? screen : pages[query?.target]),
    [query, screen]
  );

  const originX = currentPageInfo ? dimension.width / currentPageInfo.width : 0;
  const originY = currentPageInfo
    ? dimension.height / currentPageInfo.height
    : 0;

  const isWeb = useMemo(() => appInfo?.platform === 'web', [appInfo]);

  let offsetLeft = layout?.offsetLeft || 0;
  let offsetTop = layout?.offsetTop || 0;
  let sortArrComp: ElementType[] = sortBy(arrComp, ['y', 'x']);

  if (!sortArrComp.length) return <Fragment />;

  let res = [];

  const metadata = currentPageInfo
    ? sortBy(
        typeof currentPageInfo.metadata === 'string'
          ? JSON.parse(currentPageInfo.metadata)
          : currentPageInfo.metadata,
        'y'
      )
    : [];

  for (let i = 0; i < sortArrComp.length; i++) {
    let obj = JSON.parse(JSON.stringify(sortArrComp[i])),
      prevObj = sortArrComp[i - 1];

    // get function on sortArrComp[i]
    obj = {
      ...obj,
      ...(screen?.screenUuid && { screenUuid: screen?.screenUuid }),
      setEnableScrollViewScroll: sortArrComp[i].setEnableScrollViewScroll,
    };

    let newAttributes = { ...mapPropColor(obj?.attributes, appInfo) };

    obj = {
      ...obj,
      attributes: { ...obj?.attributes, ...newAttributes },
    };

    const statusBarHeight = isWeb ? 0 : HEIGHT_STATUS_BAR * originX;

    if (i == 0) {
      const marginTopPocth =
        insets.bottom + Platform.OS !== 'ios' && obj.y < statusBarHeight
          ? statusBarHeight - obj?.y || 0
          : 0;

      obj.marginTop = !isScreen
        ? obj.y - offsetTop
        : obj.y + (isShowStatusBar ? -statusBarHeight + marginTopPocth : 0);
    } else {
      const prevHeightObject =
        prevObj.type === 'label'
          ? prevObj.height + 2 * prevObj.borderWidth + prevObj.borderRadius
          : prevObj.height;
      let marginTop = obj.y - prevObj.y - prevHeightObject;
      // let marginTop =  notScaleComponent.includes(prevObj?.type) && !isWeb
      //     ? (obj.y - prevObj.y - prevHeightObject / originX) * originX
      //     : obj.y - prevObj.y - prevHeightObject;
      obj.marginTop = marginTop;
    }

    // set marginLeft and width component and merge to originX
    if (obj?.screenWidth != dimension.width) {
      obj.resizeMode = obj?.screenWidth ? true : false;
      obj.screenWidth = dimension.width;
      obj.originX = originX;
      obj.originY = originY;

      let scaleWidth = obj.width * originX;

      if (isWeb) {
        obj.isWeb = isWeb;
        if (obj.width / (currentPageInfo?.width || 1) >= 0.9) {
          obj.width = obj.width * originX;
          scaleWidth = obj.width;
        } else if (obj.width / dimension.width >= 0.9) {
          obj.width = dimension.width * 0.9;
          scaleWidth = obj.width;
        }
      } else {
        if (notScaleComponent.includes(obj?.type)) {
          obj.width = obj.width * originX;
          // obj.height = obj.height * originX;
        } else {
          obj.width = obj.width * originX;
        }
      }

      let webOffset = 0;

      if (isWeb) {
        webOffset = (scaleWidth - obj.width) / 2;
      } else {
        webOffset = notScaleComponent.includes(obj?.type)
          ? (scaleWidth - obj.width) / 2
          : 0;
      }

      if (isNil(layout?.offsetLeft)) {
        obj.marginLeft =
          obj.x === 0 ? 0 : (obj.x - offsetLeft) * originX + webOffset;
      } else {
        obj.marginLeft = (obj.x - offsetLeft) * originX;

        const objTopRight = obj.x + obj.width;
        const parentTopRight =
          (layout?.offsetLeft || 0) + (layout?.parentWidth || 0);
        if (objTopRight >= parentTopRight) {
          obj.marginLeft = obj.marginLeft - (objTopRight - parentTopRight);

          if (obj.marginLeft < 0) {
            obj.marginLeft = 0;
          }
        }
      }
    } else {
      obj.resizeMode = false;
    }

    if (isFromViewLive && currentArrComp?.length) {
      let currentSortArrComp: ElementType[] = sortBy(currentArrComp, 'y');
      const oldObj =
        find(metadata, { id: currentSortArrComp[i].id }) ||
        getComponentChild(metadata, currentSortArrComp[i].id);
      const objectWidth = oldObj
        ? oldObj.width
        : currentSortArrComp[i].width - insets.left;

      obj.width = objectWidth * originX;
    }
    let ObjectClass = mapping[obj.type] as FC;
    if (!ObjectClass) {
      console.log(`I don't know how to render ${obj.name}`);
      return <React.Fragment></React.Fragment>;
    } else {
      if (obj?.fixPosition == 'top' && isScreen) {
        delete obj.marginTop;
        const left = obj.marginLeft;
        const zIndex = obj.zIndex;
        delete obj.marginLeft;
        //delete obj.zIndex;
        res.push(
          <View
            style={{
              top: obj.y * originY,
              left,
              zIndex,
              position: 'absolute',
            }}
            key={i}
          >
            <BindingComponent key={i} obj={obj} ObjectClass={ObjectClass} />
          </View>
        );
      } else if (obj?.fixPosition == 'bottom' && isScreen) {
        delete obj.marginTop;
        const left = obj.marginLeft;
        const zIndex = obj.zIndex;
        delete obj.marginLeft;
        // delete obj.zIndex;
        res.push(
          <View
            style={{
              bottom: currentPageInfo
                ? (currentPageInfo.height - obj.height - obj.y) * originY
                : 0,
              left,
              zIndex,
              position: 'absolute',
            }}
            key={i}
          >
            <BindingComponent key={i} obj={obj} ObjectClass={ObjectClass} />
          </View>
        );
      } else {
        res.push(
          <BindingComponent key={i} obj={obj} ObjectClass={ObjectClass} />
        );
      }
    }
  }

  return <React.Fragment>{res}</React.Fragment>;
};

export default ObjectRender;
