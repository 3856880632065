export const API_ENDPOINTS = {
  V3_RECORDS: 'v3/database/:databaseId/records',
  V3_RECORDS_WEB3_TOKEN: 'v3/database/:databaseId/web3-token',
  V3_DELETE_MULTIPLE_RECORDS: 'v3/database/:databaseId/delete-multiple-records',
  APP: '/v2/share',
  SCREENS: 'v2/share/:appId}/screens',
  VISIBILITY: 'v3/database/visibility',
  CONVERT_VALUE: 'v3/database/convert-value',
  SIGNIN: 'v3/database/authmanagement',
  SIGNUP: 'v3/database/signup',
  V3_SEND_EMAIL: '/v3/app-mailer',
  V3_CSV_UPLOAD: 'v3/database/:databaseId/csv-upload',
  v3_RESET_PASSWORD: 'v3/database/reset-password',
  V3_FORGOT_PASSWORD: 'v3/database/forgot-password',
  COLLECTIONS: 'v3/database/collections',
  BINDING_SCREEN: 'v3/database/binding-component',
  ACTION_SCREEN: 'v3/database/actions',
  DATABASE_LIST_OPTION: 'v3/database/list-options',
  PLAN_PERMISSION: 'v3/plan-permission',
  BLOCTO_SIGNIN: 'v3/database/authmanagements/blocto',
};
