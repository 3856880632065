const getCurrentPosition = async () => {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: true,
      maximumAge: 0, // use 0 to disable cached
    });
  });
};

const getCurrentLocation = async () => {
  try {
    const currentPosition = await getCurrentPosition();
    return currentPosition;
  } catch (error: any) {
    console.log('===getCurrentLocation error', error);
    return;
  }
};

export default getCurrentLocation;
