import React, { useEffect, useState } from 'react';
import Icon from 'react-native-vector-icons/AntDesign';
import {
  Image,
  Modal,
  Platform,
  Pressable,
  Text,
  TextInput,
  View,
} from 'react-native';
import { WebView } from 'react-native-webview';
import { get } from 'lodash';

import appConfig from '../../../appConfig.json';
import axiosInstance from '@common/services/axiosIntance/axiosService';
import createStyle from './style';
import { IStamp } from '@common/types';
import {
  STAMP_BACKBOARD_OPTIONS,
  STAMP_IMPRINT_OPTIONS,
} from '@common/constants/stamp.constant';
import { getActions } from '@common/utils/handleActions/func/helps';
import { isCanvas } from '@common/utils/screen';

const API_BASE_URL = appConfig.baseUrl;
const DIGISHOT_DATA_KEY = 'DIGISHOT_DATA';

enum DigishotDataEvent {
  onImprintIssuedOutcome = 'onImprintIssuedOutcome',
  OnTapImprintIssuedOutcomeConfirmation = 'OnTapImprintIssuedOutcomeConfirmation',
  OnTapNoImprintOutcomeConfirmation = 'OnTapNoImprintOutcomeConfirmation',
}

type DigishotDataJson =
  | {
      event:
        | DigishotDataEvent.OnTapImprintIssuedOutcomeConfirmation
        | DigishotDataEvent.OnTapNoImprintOutcomeConfirmation;
    }
  | {
      event: DigishotDataEvent.onImprintIssuedOutcome;
      record: any;
    };

const html = ({
  appId,
  assets,
}: {
  appId: string;
  assets: {
    submittingBackboard: string;
    imprintOutcome: string;
    imprintIssuedOutcomeConfirmation: string;
  };
}) => `
  <!DOCTYPE html>
  <html lang="en">

    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Stamp Push</title>
    </head>

    <body>
      <div id="shotview-container" style="width: 375px; height: 535px; position: absolute; top: 0; left: 0;"> </div>

      <script>
        const postMessage = (data) => {
          if (window?.ReactNativeWebView?.postMessage) {
            window?.ReactNativeWebView?.postMessage(JSON.stringify({ "${DIGISHOT_DATA_KEY}": data }));
          } else {
            // send message back to iframe on web app
            localStorage.setItem("${DIGISHOT_DATA_KEY}", JSON.stringify(data));
          }
        }
        const onDigishotLoaded = () => {
          window.Digishot = window.Digishot || {};
          console.log("=== Digishot loaded");

          // ミュート状態をチェック
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          const initialAudioMuteState = audioContext.state === "suspended";

          // 1. ユーザーのアクション（タッチ）によって、audioContextのsuspendを解除
          const handleTouchStart = () => {
            if (audioContext.state === "suspended") {
              audioContext.resume();
            }
            document.removeEventListener("touchstart", handleTouchStart);
          };
          document.addEventListener("touchstart", handleTouchStart);

          try {
            window["Digishot"].Shotview.installPanelDefinitions(function (
              ResourceLoader,
              ShotviewConstants
            ) {
              const panelDef = window["Digishot"].Shotview.createPanelDefinitionBasedOn;

              return {
                _SubmittingBackboard: panelDef("_SubmittingBackboard_v1", {
                  options: {
                    size: "fullSized",
                    base: {
                      // TODO replace with image from stamp display table, use "/base_mikosea01.png" as fallback
                      image: ResourceLoader.preload(
                        "${assets.submittingBackboard}"
                      ),
                      size: "cover",
                      scale: 1,
                    },
                  },
                }),
                _ImprintIssuedOutcome: panelDef("_ImprintIssuedOutcome_v1", {
                  options: {
                    base: {
                      image: ResourceLoader.preload(
                        "${assets.imprintOutcome}"
                      ),
                      position: { x: 0, y: 0 },
                      text: {
                        text: "",
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontStyle: "bold",
                        fill: "#AB000C",
                        align: "center",
                        verticalAlign: "middle",
                        lineHeight: 1.1,
                        sv_width: 170,
                        sv_offsetY: 35,
                      },
                    },
                  },
                }),
                _AudioMuteToggleButton: panelDef("_AudioMuteToggleButton_v1", {
                  options: {
                    position: {
                      x: 120,
                      y: -190,
                    },
                    // 初期状態の設定
                    startOnAdd: initialAudioMuteState ? "off" : "on",
                    on: {
                      touchend() {
                        // ミュート状態の切り替え
                        const muted = !this._runtime.audioMuted;
                        this._runtime.audioMuted = muted;
                        this.selectAnimation(muted ? "off" : "on");
                        if (!muted && audioContext.state === "suspended") {
                          audioContext.resume();
                        }
                      },
                    },
                  },
                }),
                _ImprintIssuedOutcomeConfirmation: panelDef(
                  "_ImprintIssuedOutcomeConfirmation_v1",
                  {
                    options: {
                      zLevel: -10,
                      base: {
                        image: ResourceLoader.bundled("${assets.imprintIssuedOutcomeConfirmation}"),
                        position: { x: 0, y: 5 },
                        scale: 0.5,
                      },
                      on: {
                        tap: () => {
                          const digishotDataJson = { event: "${DigishotDataEvent.OnTapImprintIssuedOutcomeConfirmation}" };
                          postMessage(digishotDataJson);
                        },
                      },
                    },
                  }
                ),
                _NoImprintOutcomeConfirmation: panelDef(
                  "_NoImprintOutcomeConfirmation_v1",
                  {
                    options: {
                      on: {
                        tap: () => {
                          const digishotDataJson = { event: "${DigishotDataEvent.OnTapNoImprintOutcomeConfirmation}" };
                          postMessage(digishotDataJson);
                        },
                      },
                    },
                  }
                ),
              };
            });

            const serviceURI = "${API_BASE_URL}/digishot/daas?appId=${appId}";
            function createShotview() {
              const shotview = new (window["Digishot"].Shotview)({
                serviceURI,
                container: "shotview-container",
                userData: {},
                onPrepared: () => {
                  console.log("===shotview prepared");
                },
                onImprintIssuedOutcome: (record) => {
                  console.log("===onImprintIssuedOutcome", record);
                  if (record?.stampcode) {
                    // send message back to iframe on native app
                    const digishotDataJson = { record, event: "${DigishotDataEvent.onImprintIssuedOutcome}" };
                    postMessage(digishotDataJson);
                  }
                },
                onNoImprintOutcome: (...args) => {
                  // console.log("===onNoImprintOutcome", args);
                },
              });

              // 2. Shotviewのインスタンス生成前に、audioContext.stateがsuspendedでないことを確認し、ミュートを解除
              if (!initialAudioMuteState) {
                shotview._runtime.audioMuted = false;
              }
            }
            
            createShotview();
          } catch (e) {
            console.error(e);
          }
        };
      </script>
      <script src="${API_BASE_URL}/digishot/digishot/shotview/konva@3.4.1/konva.js"
        onload="console.log('=== Konva loaded')"></script>
      <script src="${API_BASE_URL}/digishot/shotview?appId=${appId}" onload="onDigishotLoaded()"></script>
    </body>
  </html>
`;

const Stamp = (attrs: IStamp) => {
  const styles = createStyle(attrs);
  const [isShow, setIsShow] = useState(false);
  const [isShowPasscodePanel, setIsShowPasscodePanel] = useState(false);
  const [passcode, setPasscode] = useState<string | undefined>();
  const [stampId, setStampId] = useState<string | undefined>();
  const [stampSuccess, setStampSuccess] = useState<boolean>(false);
  const [webviewKey, setWebviewKey] = useState<number>(0);

  const stampPattern = get(
    attrs,
    'dependencies.appInfor.metadata.digishot.pattern'
  );
  const { backboardImage, zIndex, onPress } = attrs;

  const backboardImageSrc = STAMP_BACKBOARD_OPTIONS.find(
    (o) => o.name === backboardImage
  )?.placeholder;

  const pushStampActionExample = async () => {
    await onPress(getActions(attrs.actions), {
      record: { stampID: 'AA' },
    });
  };

  const handleDigishotData = async (data: DigishotDataJson) => {
    console.log('===handleDigishotData', data);
    switch (data.event) {
      case DigishotDataEvent.onImprintIssuedOutcome:
        if (attrs.validate) {
          try {
            const res = await axiosInstance({
              method: 'POST',
              url: '/digishot/stamp/validate',
              params: {
                appId: attrs.dependencies?.appInfor?.appId,
              },
              data: {
                stampID: data.record.stampcode,
                stampType:
                  stampPattern === 'shop-stamp' ? 'shopStamp' : 'rally',
              },
            });

            if (res) {
              await onPress(getActions(attrs.actions), {
                record: { stampID: data?.record?.stampcode },
              });
            }
          } catch (error: unknown) {
            return setIsShow(true);
          }
        } else {
          await onPress(getActions(attrs.actions), {
            record: { stampID: data?.record?.stampcode },
          });
        }

        break;
      case DigishotDataEvent.OnTapImprintIssuedOutcomeConfirmation:
        // TODO: on back
        break;
      case DigishotDataEvent.OnTapNoImprintOutcomeConfirmation:
        // TODO: onTogglePasscodePanel
        setIsShowPasscodePanel(true);
        break;
    }
  };

  const handleCloseErrorModal = () => {
    setIsShow(false);
  };
  const handleClosePasscodeModal = () => {
    setIsShowPasscodePanel(false);
    setStampSuccess(false);
  };
  const handlePasscodeOnchange = (value: any) => {
    setPasscode(value);
  };
  const handleStampIdOnchange = (value: any) => {
    setStampId(value);
  };
  const handlePasscodeSubmit = async () => {
    try {
      const res = await axiosInstance({
        method: 'POST',
        url: '/digishot/stamp/validate',
        params: {
          appId: attrs.dependencies?.appInfor?.appId,
        },
        data: {
          stampID: stampId,
          stampType: stampPattern === 'shop-stamp' ? 'shopStamp' : 'rally',
        },
      });

      if (res) {
        await onPress(getActions(attrs.actions), {
          record: { stampID: stampId },
        });

        setWebviewKey((prev) => prev + 1);
        setStampSuccess(true);
        setStampId('');
        setPasscode('');
      }
    } catch (error: unknown) {
      setWebviewKey((prev) => prev + 1);
      setIsShowPasscodePanel(false);
      setStampId('');
      setPasscode('');
      return setIsShow(true);
    }
  };

  if (Platform.OS === 'web' && isCanvas()) {
    return (
      <View style={{ zIndex, width: attrs.width, height: attrs.height }}>
        <img
          src={
            backboardImageSrc
              ? backboardImageSrc
              : `${appConfig.baseUrl}/digishot/shotview/r/default/koto/base01.png`
          }
          alt="backboard"
        />
      </View>
    );
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      localStorage.removeItem(DIGISHOT_DATA_KEY);
      let interval = setInterval(() => {
        const data = localStorage.getItem(DIGISHOT_DATA_KEY);
        if (data) {
          const digishotDataJson = JSON.parse(data) as DigishotDataJson;
          handleDigishotData(digishotDataJson);
          clearInterval(interval);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  return (
    <View style={styles.container}>
      <View
        style={{
          borderRadius: 16,
          overflow: 'hidden',
        }}
      >
        <WebView
          key={webviewKey}
          style={{
            height: 535,
            width: 375,
          }}
          scrollEnabled={false}
          source={{
            html: html({
              appId: attrs.dependencies.appInfor.appId,
              assets: {
                imprintIssuedOutcomeConfirmation:
                  'https://mikosea.io/next_mikosea.png',
                imprintOutcome:
                  STAMP_IMPRINT_OPTIONS.find(
                    (o) => o.name === attrs.imprintImage
                  )?.placeholder || 'https://mikosea.io/imprint_mikosea01.png',
                submittingBackboard:
                  backboardImageSrc ||
                  'https://mikosea.s3.ap-northeast-1.amazonaws.com/common/stamp_backboard.png',
              },
            }),
          }}
          originWhitelist={['*']}
          javaScriptEnabled
          onMessage={(event) => {
            //check is event from webview or not, only work on mobile app
            const data = event.nativeEvent.data;
            const digishotDataJson = (
              typeof data === 'string' ? JSON.parse(data) : data
            )[DIGISHOT_DATA_KEY] as DigishotDataJson;
            if (digishotDataJson) {
              handleDigishotData(digishotDataJson);
            }
          }}
        />
      </View>
      <Modal
        visible={isShow}
        animationType="fade"
        transparent={true}
        onRequestClose={handleCloseErrorModal}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalTop}>
              <Text style={styles.modalTopTitle}>複数のスタンプ</Text>
              <Icon
                style={{
                  marginLeft: 'auto',
                  marginVertical: 'auto',
                }}
                name={'close'}
                size={20}
                onPress={handleCloseErrorModal}
              />
            </View>
            <View style={styles.modalBody}>
              <View style={styles.modalBodyContent}>
                <View
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <Image
                    source={{
                      uri: 'https://mikosea.s3.ap-northeast-1.amazonaws.com/common/stamp-failure.png',
                    }}
                    style={{ width: 64, height: 64 }}
                  />
                  <Text
                    style={{ textAlign: 'center', marginTop: 16, width: 200 }}
                  >
                    このスタンプは今日すでに使用されています。
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={isShowPasscodePanel}
        onRequestClose={handleClosePasscodeModal}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalTop}>
              <Icon
                style={{
                  marginLeft: 'auto',
                  marginVertical: 'auto',
                }}
                name={'close'}
                size={20}
                onPress={handleClosePasscodeModal}
              />
            </View>
            <View style={styles.modalBody}>
              <View style={styles.modalBodyContent}>
                {!stampSuccess ? (
                  <View
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Text style={{ width: 220, textAlign: 'center' }}>
                      スタンプ裏に記載されているID(英字1-2桁)を入力してください
                    </Text>
                    <TextInput
                      style={{
                        height: 40,
                        margin: 12,
                        borderWidth: 1,
                        padding: 10,
                        width: 100,
                        borderRadius: 4,
                        textAlign: 'center',
                      }}
                      placeholder="AA"
                      maxLength={2}
                      onChangeText={(stampId) => handleStampIdOnchange(stampId)}
                    />
                    <Text style={{ width: 200, textAlign: 'center' }}>
                      パスコードを入力してください
                    </Text>
                    <TextInput
                      style={{
                        height: 40,
                        margin: 12,
                        borderWidth: 1,
                        padding: 10,
                        width: 100,
                        borderRadius: 4,
                        textAlign: 'center',
                      }}
                      keyboardType="numeric"
                      placeholder="* * * * * *"
                      maxLength={6}
                      onChangeText={(passcode) =>
                        handlePasscodeOnchange(passcode)
                      }
                    />
                    <View
                      style={{
                        marginTop: 16,
                      }}
                    >
                      <Pressable
                        style={({ pressed }) => {
                          return [
                            styles.button,
                            (passcode === undefined ||
                              passcode?.length < 6) && {
                              backgroundColor: 'silver',
                            },
                            pressed && { backgroundColor: '#96D1F2' },
                          ];
                        }}
                        onPress={handlePasscodeSubmit}
                        disabled={
                          passcode === undefined ||
                          passcode?.length < 6 ||
                          stampId === undefined
                        }
                      >
                        <Text style={styles.text}>バスコードを送信</Text>
                      </Pressable>
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      source={{
                        uri: 'https://mikosea.s3.ap-northeast-1.amazonaws.com/common/stamp-success.png',
                      }}
                      style={{ width: 64, height: 64, marginBottom: 16 }}
                    />
                    <Text style={styles.text}>スタンプが成功しました</Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default Stamp;
