import Loading from '@common/components/Loading';
import StatusBar from '@common/components/StatusBar';
import useBindingScreen from '@common/hooks/useBindingScreen';
import useLineAction from '@common/hooks/useLineAction';
import useScreenAction from '@common/hooks/useScreenAction';
import { appInfoSelector, locationSelector } from '@common/redux/selectors/app';
import { profileSelector } from '@common/redux/selectors/auth';
import {
  currentRecordSelector,
  realtimeDatabaseSelector,
} from '@common/redux/selectors/database';
import { getValueFields } from '@common/redux/selectors/formInputs';
import { localeSelector } from '@common/redux/selectors/language';
import { dimensionSelector } from '@common/redux/selectors/page';
import { useLocation } from '@common/routes/hooks';
import { PropRender } from '@common/types/';
import { getAttributesFixed, getAttributesNormal } from '@common/utils';
import {
  eachRecursiveTable,
  flattenInputObject,
  memoriesConditional,
} from '@common/utils/component';
import { get } from 'lodash';
import { default as queryString, ParsedQuery } from 'query-string';
import React, { useMemo } from 'react';
import { Platform, SafeAreaView, View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { loadingPageSelector } from '../../../redux/selectors/page';
import BackgroundImage from '../RenderScreen/BackgroundImage';
import FixedComponent from './FixedObject';
import LoseConnectLiveStream from './LoseConnectLiveStream';
import NormalComponent from './DynamicObject';
import createStyle from './styles';

function Render({ screen }: PropRender) {
  const appInfo = useSelector(appInfoSelector);
  const dimension = useSelector(dimensionSelector);
  const loadingPage = useSelector(loadingPageSelector);
  const valueInputs = useSelector(getValueFields);
  const appInfor = useSelector(appInfoSelector);
  const currentRecord = useSelector(currentRecordSelector);
  const databases = useSelector(realtimeDatabaseSelector);
  const loggedUser = useSelector(profileSelector);
  const currentLocation = useSelector(locationSelector);
  const locale = useSelector(localeSelector);

  const { search } = useLocation();

  const { hiddenStatusBar }: ParsedQuery<any> = useMemo(
    () => queryString.parse(search),
    [search]
  );

  const tableRefresh = useMemo(() => {
    const pullToRefresh = get(screen, 'attributes.pullToRefresh', false);
    if (!pullToRefresh) return [];

    const tables = eachRecursiveTable(screen);
    return tables.reduce((preValue: any, current: string) => {
      return { ...preValue, [current]: databases[current] };
    }, {});
  }, [databases, screen]);

  const inputs = useMemo(() => {
    const inputDependencies = flattenInputObject(screen);
    if (inputDependencies.length <= 0) return;
    return inputDependencies.reduce((preValue: any, current: string) => {
      return { ...preValue, [current]: valueInputs[current] };
    }, {});
  }, [screen, valueInputs]);

  const dependencies: any = useMemo(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
      appInfor,
      valueInputs: inputs,
      routeParam: currentRecord,
      table: tableRefresh,
      timeZone,
      loggedUser: loggedUser?.id,
      currentLocation,
      locale,
    };
  }, [
    inputs,
    appInfor,
    currentRecord,
    screen?.screenId,
    tableRefresh,
    loggedUser,
    currentLocation,
  ]);

  useBindingScreen({ screen, dependencies, appInfo });

  useLineAction({ dependencies });

  useScreenAction({ screen, dependencies });

  const isWeb = useMemo(() => appInfo?.platform === 'web', [appInfo]);

  const isShowStatusBar =
    !screen.isModal &&
    !(Platform.OS === 'android' || Platform.OS === 'ios') &&
    !hiddenStatusBar &&
    screen?.attributes?.statusBarStyle !== 'hidden';

  // const originX = dimension.width / screen.width;
  const originY = dimension.height / screen.height;

  let arrNormal = getAttributesNormal(screen);
  let arrFixed = getAttributesFixed(screen);

  const styles = createStyle(screen);

  if (loadingPage) return <Loading />;

  return (
    <View style={styles.container}>
      {screen?.attributes?.backgroundImage && (
        <BackgroundImage
          source={screen?.attributes?.backgroundImage}
          resizeMode={screen?.attributes?.backgroundSize}
          positionX={screen?.attributes?.backgroundPositionX}
          positionY={screen?.attributes?.backgroundPositionY}
        />
      )}

      {!isWeb && (
        <StatusBar
          isShowStatusBar={isShowStatusBar}
          barStyle={screen?.attributes?.statusBarStyle}
        />
      )}

      <SafeAreaView style={styles.safeViewContainer}>
        <LoseConnectLiveStream screen={screen} originY={originY} />

        <NormalComponent arrNormal={arrNormal} screen={screen} />
      </SafeAreaView>

      <FixedComponent arrFixed={arrFixed} screen={screen} />
    </View>
  );
}

export default React.memo(
  Render,
  memoriesConditional(['dependencies', 'screen', 'Render'])
);
